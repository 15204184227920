import React from 'react';
import { ASSET_ICONS } from '../../../Constants/Icons';
import { READONLY_STATUS } from '../../../Constants/Risks';
import { RiskWithAssetInfo, iRisk } from '../../../Global/riskReducer';
import { ColumnProps } from '../../../Reusables/table/Table';
import TagPills from '../../../Reusables/TagPills';
import { getRiskColor, getTimeMetrics } from '../../../utils/riskUtils';
import { CriticalityPill } from '../../Assets/AssetDetails/InfoRowRenderers/CriticalityRenderer';
import { SOURCE_TEXT } from '../../Assets/constants';
import styled from 'styled-components';
import { Primitive } from '@rtkwlf/fenrir-react';

const StyledRowCheckbox = styled.input`
  position: relative;
  top: 2px;
  border-color: #cccccc;
  border: 1px solid;
  border-radius: 4px;
  border-width: 1px;
`;

const StyledCheckbox = styled.input`
  position: relative;
  border-color: #cccccc;
  border: 1px solid;
  border-radius: 4px;
  border-width: 1px;
`;

const RiskScoreRender = styled(Primitive.div)<{ $riskColor: string }>`
  width: 66px;
  border-radius: 36px;
  background: ${(props) => props.$riskColor};
`;

const getMetricsData = (risk: iRisk) => {
  return getTimeMetrics(
    risk.attributes.firstIdentified,
    risk.attributes.resolvedTime
  );
};

export const useRiskTableColumns = (
  riskArray: any,
  selectAll: any,
  highlightList: any,
  selectRisk: any,
  hiddenColumnKeys: any
) => {
  return React.useMemo(() => {
    const allColumns: ColumnProps<RiskWithAssetInfo>[] = [
      {
        key: 'row-select',
        title: (
          <StyledCheckbox
            data-testid={`risk-select-all`}
            type='checkbox'
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              selectAll(e.currentTarget.checked);
            }}
            disabled={
              riskArray.filter(
                (r: iRisk) => !READONLY_STATUS.includes(r.attributes.status)
              ).length === 0
            }
            checked={
              highlightList.length > 0 &&
              highlightList.length ===
                riskArray.filter(
                  (r: iRisk) => !READONLY_STATUS.includes(r.attributes.status)
                ).length
            }
          />
        ),
        justify: 'flex-start',
        render: ({ record: risk }) => (
          <div>
            <StyledRowCheckbox
              type='checkbox'
              disabled={READONLY_STATUS.includes(risk.attributes.status)}
              title={
                READONLY_STATUS.includes(risk.attributes.status)
                  ? 'Mitigated and Obsolete risks are readonly'
                  : ''
              }
              onClick={(e: React.FormEvent<HTMLInputElement>) => {
                selectRisk(risk, e);
                e.stopPropagation();
              }}
              readOnly
              checked={highlightList?.includes(risk.id)}
            />
          </div>
        ),
      },
      {
        key: 'source',
        title: 'Source',
        sortable: true,
        justify: 'flex-start',
        render: ({ record: risk }) => (
          <>
            {ASSET_ICONS[risk.source] ? (
              <i className={`fa ${ASSET_ICONS[risk.source]}`} />
            ) : (
              risk.source || ''
            )}
            {`  ${SOURCE_TEXT[risk.source] || ''}`}
          </>
        ),
      },
      {
        key: 'host',
        title: 'Host',
        sortable: true,
        justify: 'flex-start',
        render: ({ record: risk }) =>
          risk.resource || risk.attributes.hostDisplayName || risk.host,
      },
      {
        key: 'issueName',
        dataIndex: 'issueName',
        sortable: true,
        title: 'Issue',
        justify: 'flex-start',
      },
      {
        key: 'issueLevel',
        sortable: true,
        title: 'Risk Score',
        justify: 'flex-start',
        render: ({ record: risk }) => (
          <RiskScoreRender
            display='flex'
            justifyContent='space-evenly'
            alignItems='center'
            textColor='monoBlack'
            fontWeight='bold'
            $riskColor={getRiskColor(risk.issueLevel)}
          >
            {risk.issueLevel.toFixed(1)}
          </RiskScoreRender>
        ),
      },
      {
        key: 'criticality',
        sortable: false,
        title: 'Asset Criticality',
        justify: 'flex-start',
        render: ({ record: { assetCriticality } }) => {
          return <CriticalityPill criticality={assetCriticality} />;
        },
      },
      {
        key: 'os',
        dataIndex: 'assetOS',
        sortable: false,
        title: 'OS',
        justify: 'flex-start',
      },
      {
        key: 'issueAction',
        dataIndex: 'issueAction',
        sortable: true,
        title: 'Action',
        justify: 'flex-start',
      },
      {
        key: 'assetCategory',
        dataIndex: 'assetCategory',
        sortable: false,
        title: 'Asset Category',
        justify: 'flex-start',
      },
      {
        key: 'state',
        dataIndex: 'state',
        sortable: true,
        title: 'State',
        justify: 'flex-start',
      },
      {
        key: 'status',
        title: 'Status',
        sortable: true,
        justify: 'flex-start',
        render: ({ record: risk }) => risk.attributes.status,
      },
      {
        key: 'statusReason',
        sortable: false,
        title: 'Status Reason',
        justify: 'flex-start',
        render: ({ record: risk }) => risk.attributes.statusReason || 'N/A',
      },
      {
        key: 'resolutionDate',
        title: 'Resolution Date',
        justify: 'flex-start',
        render: ({ record: risk }) => (
          <>{getMetricsData(risk).resolutionDate}</>
        ),
      },
      {
        key: 'age',
        title: 'Age',
        justify: 'flex-start',
        render: ({ record: risk }) => <>{getMetricsData(risk).age}</>,
      },
      {
        key: 'daysToResolution',
        title: 'Days To Resolution',
        justify: 'flex-start',
        render: ({ record: risk }) => (
          <>{getMetricsData(risk).daysToResolution}</>
        ),
      },
      {
        key: 'assetTags',
        title: 'Asset Tags',
        width: 10,
        render: ({ record: { assetTags } }) => (
          <TagPills tags={assetTags} tooltipPosition='left' />
        ),
      },
      {
        key: 'deploymentID',
        dataIndex: 'deploymentID',
        sortable: true,
        title: 'Deployment ID',
        justify: 'flex-start',
        render: ({ record: risk }) =>
          risk?.source === 'sensor' ? risk?.deploymentID : 'N/A',
      },
      {
        key: 'mostRecentDetection',
        dataIndex: 'createTime',
        sortable: false,
        title: 'Most Recent Detection',
        justify: 'flex-start',
        width: 15,
        render: ({ record: risk }) => risk?.createTime,
      },
    ];

    return allColumns.filter(
      (column) => !hiddenColumnKeys.includes(column.key as string)
    );
  }, [hiddenColumnKeys, highlightList, riskArray, selectAll, selectRisk]);
};
