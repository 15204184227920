import type { Capabilities, ResponseError } from '@rtkwlf/rendall';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useRendallApi } from '../../useRendallApi';
import { customerSelectors } from '../../../../Global/customerReducer';

type Options<T> = Omit<
  UseQueryOptions<Capabilities, ResponseError>,
  'queryFn' | 'queryKey'
>;

export const getLegacyCapabilitiesKey = (organizationId: string) => [
  organizationId,
  'rendall',
  'get-capabilities',
];

export const useLegacyCapabilitiesOptions = <T = Capabilities>(
  options?: Options<T>
) => {
  const api = useRendallApi();
  const organizationId = useSelector(customerSelectors.getCustomerId);

  return {
    enabled: Boolean(organizationId),
    staleTime: 1000 * 60 * 5,
    queryFn: () => api.getCapabilities({ organizationId }),
    queryKey: getLegacyCapabilitiesKey(organizationId),
    ...options,
  };
};

export const useLegacyCapabilities = <T = Capabilities>(
  options?: Options<T>
) => {
  return useQuery(useLegacyCapabilitiesOptions(options));
};
