import React, { useMemo } from 'react';
import styled from 'styled-components';
import './Menu/ControlledMenu.css';

import { useSelector } from 'react-redux';
import { customerSelectors } from '../Global/customerReducer';
import { DropdownMenu, Primitive } from '@rtkwlf/fenrir-react';
import { useLegacyCapabilities } from '../apiClient/rendall/queries/use-capabilities/use-capabilities';

const StyledSwitchIcon = styled.i`
  font-size: 16px;
  color: white;
  margin: 20px 0px 0px 12px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  padding: 10px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const PortalMenu = () => {
  const { data: legacyCapabilities, isLoading } = useLegacyCapabilities();
  const currentCustomerDetails = useSelector(
    customerSelectors.getCurrentCustomerDetails
  );
  const customerId = currentCustomerDetails?.deploymentID
    ? currentCustomerDetails.deploymentID.replace(/\d+$/g, '')
    : '';

  const menuItems = useMemo(() => {
    const items: { label: string; link: string }[] = [];

    if (legacyCapabilities?.canAccessManageAwareness) {
      items.push({
        label: 'Managed Awareness Portal',
        link: `https://sat.arcticwolf.com/Home/Dashboard?cid=${customerId}`,
      });
    }

    items.push({
      label: 'Unified Portal',
      link: `https://dashboard.arcticwolf.com?id=${currentCustomerDetails?.id}`,
    });

    return items;
  }, [
    legacyCapabilities?.canAccessManageAwareness,
    customerId,
    currentCustomerDetails,
  ]);

  if (!isLoading && !menuItems.length) {
    return <Primitive.div height='2rem' width='2rem' />;
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <StyledSwitchIcon
          className='fa fa-th'
          aria-label='Portal Menu'
          data-testid='portalMenu'
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align='start' width='18rem'>
        <DropdownMenu.Label>switch to</DropdownMenu.Label>
        <DropdownMenu.Group>
          {menuItems.map((item) => (
            <DropdownMenu.ExternalLinkItem
              href={item.link}
              key={item.label}
              target='_blank'
            >
              <DropdownMenu.ExternalLinkItemText>
                {item.label}
              </DropdownMenu.ExternalLinkItemText>
            </DropdownMenu.ExternalLinkItem>
          ))}
        </DropdownMenu.Group>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
