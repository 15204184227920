import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  HStack,
  Menu,
  Popover,
  Primitive,
  Select,
  Text,
  TextField,
} from '@rtkwlf/fenrir-react';
import {
  NestedRow,
  ColumnProps,
  SortState,
  Table,
} from '../../../Reusables/table/Table';
import { GREY, BLUE } from '../../../Constants/Styles';
import { ThreeDots } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { HIDDEN_COLUMN_KEYS_STORAGE_KEY } from '../utils';
import { useAdsDevice } from '../../../apiClient/asset-data-service/queries/useAdsDevices';

const countList = [
  { value: '5', text: '5' },
  { value: '10', text: '10' },
  { value: '15', text: '15' },
  { value: '20', text: '20' },
  { value: '50', text: '50' },
  { value: '100', text: '100' },
  { value: '0', text: 'All' },
];

const StyleSpan = styled.span`
  color: ${GREY.bright};
  margin: 0 8px 5px;
`;

const StylePaginationFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const WrapLoaderStyle = styled.div`
  margin: 75px 0px;
  display: flex;
  justify-content: center;
`;

export type Props<T> = {
  data: any;
  columns: ColumnProps<T>[];
  nestedRow?: NestedRow<T>;
  hideColumnsFromMenu?: string[];
  hideDefaultTableColumn?: string[];
  handleSort?: (key: string, state: SortState) => void;
  isLoading?: boolean;
  isSearch?: boolean;
  setPageLimit?: (selectedValue: string) => void;
  pageLimit?: string;
  headerChildren?: React.ReactElement;
  isProcessing?: boolean;
};

const useCustomColumns = <T extends {}>(
  allColumns: ColumnProps<T>[],
  hideColumnsFromMenu?: string[],
  hideDefaultTableColumn?: string[]
) => {
  const [hiddenColumnKeys, setHiddenColumnKeys] = useState<
    Array<string | number>
  >(hideDefaultTableColumn || []);

  const updateHiddenColumns = useCallback(
    (key: string | number, checked: boolean) => {
      setHiddenColumnKeys((prevHiddenKeys) => {
        let newKeys;
        if (checked) {
          newKeys = prevHiddenKeys.filter((k) => k !== key);
        } else {
          newKeys = [...prevHiddenKeys, key];
        }
        window.localStorage.setItem(
          HIDDEN_COLUMN_KEYS_STORAGE_KEY,
          JSON.stringify(newKeys)
        );
        return newKeys;
      });
    },
    []
  );

  const visibleColumns = useMemo(
    () => allColumns.filter((c) => !hiddenColumnKeys.includes(c.key)),
    [allColumns, hiddenColumnKeys]
  );

  const columnsMenu = (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button
          margin='zero'
          variant='secondary'
          iconRight={<i className='fa fa-caret-down' />}
        >
          Columns
        </Button>
      </Popover.Trigger>
      <Popover.Content>
        <Menu.Root>
          {allColumns.map((column) => {
            if (hideColumnsFromMenu?.includes(column.key as string)) {
              return null;
            }
            return (
              <Menu.Item key={column.key}>
                <Checkbox.Root
                  margin='zero'
                  onCheckedChange={(isChecked: boolean) =>
                    updateHiddenColumns(column.key, isChecked)
                  }
                  name={column.key as string}
                  isChecked={!hiddenColumnKeys.includes(column.key)}
                >
                  <Checkbox.Text>{column.title}</Checkbox.Text>
                </Checkbox.Root>
              </Menu.Item>
            );
          })}
        </Menu.Root>
      </Popover.Content>
    </Popover.Root>
  );

  return {
    visibleColumns,
    columnsMenu,
  };
};

const AssetTable = <T extends {}>({
  data,
  columns,
  nestedRow,
  hideColumnsFromMenu,
  hideDefaultTableColumn,
  handleSort,
  isLoading,
  isSearch,
  pageLimit,
  setPageLimit,
  headerChildren,
  isProcessing,
}: Props<T>) => {
  const urlParams = new URLSearchParams(window.location.search);
  const adsAssetId = urlParams.get('adsAssetId') || '';
  const { data: adsDevice } = useAdsDevice(adsAssetId);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedPagePerValue, setPagePerCount] = useState<string>(
    countList[0].value
  );
  const [searchText, setSearchText] = useState('');
  const [tableData, setTableData] = useState(data);
  const getInitialHiddenColumns = () => {
    const hiddenColumnStorageKey = window.localStorage.getItem(
      HIDDEN_COLUMN_KEYS_STORAGE_KEY
    );

    if (hiddenColumnStorageKey) {
      return JSON.parse(hiddenColumnStorageKey);
    }

    return hideDefaultTableColumn;
  };

  useEffect(() => {
    let filterTableData = data;
    if (adsAssetId)
      filterTableData = filterTableData.filter((asset: { id: string }) =>
        adsDevice?.legacyDeviceIds?.includes(asset.id)
      );
    if (isSearch) {
      const filterText = searchText.trim().replace(/,/g, '').toLowerCase();

      if (filterText !== '') {
        filterTableData = filterTableData.filter((e: any) => {
          const arrTempData: string = Object.values(e).toString().toLowerCase();

          return arrTempData.includes(filterText);
        });
      }
    }

    setCurrentPage(1);
    setTableData(filterTableData);
  }, [data, isSearch, searchText, adsDevice, adsAssetId]);

  const { visibleColumns, columnsMenu } = useCustomColumns(
    columns,
    hideColumnsFromMenu,
    getInitialHiddenColumns()
  );
  const totalRows = tableData?.length;
  const displayCount = Number(
    pageLimit
      ? pageLimit
      : selectedPagePerValue
        ? selectedPagePerValue
        : countList[0]
  );
  const totalPages = displayCount ? Math.ceil(totalRows / displayCount) : 1;
  const startIndex = (currentPage - 1) * displayCount;
  const tempRowCount =
    startIndex + displayCount > totalRows
      ? totalRows
      : startIndex + displayCount;
  const endIndex = displayCount ? tempRowCount : totalRows;
  const paginatedData = displayCount
    ? tableData.slice(startIndex, endIndex)
    : tableData;

  const HeaderPaginationBlock = useMemo(() => {
    const handlePageChange = (optionSelected: string) => {
      setCurrentPage(1);
      if (setPageLimit) {
        setPageLimit(optionSelected);
      } else {
        setPagePerCount(optionSelected);
      }
    };
    return (
      <HStack flexWrap='wrap' marginY='small' xAlign='between'>
        <HStack gap='small'>
          <Text styledAs='body3'>Show</Text>
          <Primitive.div width='5rem'>
            <Select
              isClearable={false}
              value={pageLimit || selectedPagePerValue}
              options={countList}
              onValueChange={(value) => handlePageChange(value)}
            />
          </Primitive.div>
          <Text styledAs='body3'>Entries</Text>
        </HStack>

        <HStack gap='small'>
          {isSearch && (
            <TextField
              placeholder='Search'
              iconLeft={<FontAwesomeIcon icon={faSearch} />}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchText(e.target.value)
              }
            />
          )}
          {headerChildren}
          {columnsMenu}
        </HStack>
      </HStack>
    );
  }, [
    columnsMenu,
    isSearch,
    pageLimit,
    selectedPagePerValue,
    setPageLimit,
    headerChildren,
  ]);

  const FooterPaginationBlock = useMemo(() => {
    return (
      <StylePaginationFooter data-testid='catalog-table-footer'>
        <StyleSpan>
          Showing {totalRows !== 0 ? startIndex + 1 : 0} - {endIndex} of{' '}
          {totalRows} matches
        </StyleSpan>
        {totalPages > 1 ? (
          <>
            <Button
              isDisabled={currentPage === 1}
              onClick={() => setCurrentPage(1)}
              iconLeft={<i className='fa fa-angle-double-left' />}
            />
            <Button
              isDisabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
              iconLeft={<i className='fa fa-angle-left' />}
            />
            <input
              name='page-number'
              style={{
                width: '45px',
                textAlign: 'center',
                height: '32px',
                marginBottom: '5px',
                marginRight: '5px',
              }}
              value={currentPage}
              disabled
            />
            <StyleSpan>{` of ${totalPages} pages `}</StyleSpan>
            <Button
              isDisabled={totalPages === currentPage}
              onClick={() => setCurrentPage(currentPage + 1)}
              iconLeft={<i className='fa fa-angle-right' />}
            />
            <Button
              isDisabled={totalPages === currentPage}
              onClick={() => setCurrentPage(totalPages)}
              iconLeft={<i className='fa fa-angle-double-right' />}
            />
          </>
        ) : null}
      </StylePaginationFooter>
    );
  }, [startIndex, endIndex, totalRows, totalPages, currentPage]);

  return (
    <>
      {HeaderPaginationBlock}
      <div
        style={{
          overflowX: 'auto',
          paddingBottom: '94px',
          marginBottom: '-84px',
          marginTop: '-20px',
          paddingTop: '20px',
        }}
      >
        {/*@ts-ignore*/}
        <Table
          data={paginatedData}
          columns={visibleColumns}
          nestedRow={nestedRow}
          onSort={handleSort}
          loading={isLoading || (isProcessing && paginatedData.length > 0)}
          emptyComponent={
            isProcessing ? (
              <WrapLoaderStyle>
                <ThreeDots color={BLUE.tertiary} height={120} width={120} />
              </WrapLoaderStyle>
            ) : (
              <div>No Data Available</div>
            )
          }
        />
      </div>
      {FooterPaginationBlock}
    </>
  );
};
export default AssetTable;
