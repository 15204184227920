import { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  HStack,
  Primitive,
  Text,
} from '@rtkwlf/fenrir-react';
import { ThreeDots } from 'react-loader-spinner';
import { BLUE } from '../Constants/Styles';
import { ModalBox } from './ModalBox';
import { Tooltip } from '@rtkwlf/fenrir-react';
import { agentRSE, isFeatureEnabled } from '../../public/js/features';

export type Props = {
  allAgent?: boolean;
  isRescanDisabled: boolean;
  isModalOpen: boolean;
  isLoading: boolean;
  modalTitle: string;
  modalMessage: ReactElement | string;
  handleRescanClick: () => void;
  handleModalConfirm: (debugScan: boolean) => Promise<void>;
  handleModalCancel: () => void;
};

const RescanControl = ({
  allAgent,
  isRescanDisabled,
  isModalOpen,
  isLoading,
  modalTitle,
  modalMessage,
  handleRescanClick,
  handleModalConfirm,
  handleModalCancel,
}: Props) => {
  const [debugScan, setDebugScan] = useState<boolean>(false);
  const ff_RSE = isFeatureEnabled(agentRSE);
  const onRescan = async () => {
    // send rescan request
    await handleModalConfirm(debugScan);

    // close modal
    handleModalCancel();
  };

  useEffect(() => {
    setDebugScan(false);
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && (
        <ModalBox
          title={modalTitle}
          onCloseModal={handleModalCancel}
          width='520px'
          footer={
            <HStack yAlign='center' xAlign='right' gap='small'>
              {!isLoading && (
                <>
                  {!ff_RSE && allAgent && (
                    <Checkbox.Root
                      onCheckedChange={setDebugScan}
                      isChecked={debugScan}
                    >
                      <Checkbox.Text>Enable Debug Scan</Checkbox.Text>
                    </Checkbox.Root>
                  )}
                  <Button
                    style={{ margin: 0 }}
                    data-testid='rescan-modal-confirm'
                    variant='primary'
                    iconLeft={<i className='fa fa-repeat' />}
                    onClick={() => onRescan()}
                  >
                    Rescan
                  </Button>
                  <Button
                    style={{ margin: 0 }}
                    data-testid='rescan-modal-cancel'
                    onClick={handleModalCancel}
                    variant='secondary'
                  >
                    Cancel
                  </Button>
                </>
              )}
            </HStack>
          }
        >
          <HStack paddingX='large' paddingBottom='large'>
            {isLoading ? (
              <HStack xAlign='center' yAlign='center'>
                <ThreeDots color={BLUE.tertiary} height={50} width={50} />
              </HStack>
            ) : (
              <Text styledAs='body1' lineHeight={1.5}>
                {modalMessage}
              </Text>
            )}
          </HStack>
        </ModalBox>
      )}
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <Primitive.div cursor='pointer'>
            <Button
              marginBottom='zero'
              variant='secondary'
              iconLeft={<i className='fa fa-repeat' />}
              isDisabled={isRescanDisabled}
              onClick={handleRescanClick}
              data-testid='rescan-selected'
            >
              Rescan
            </Button>
          </Primitive.div>
        </Tooltip.Trigger>
        {isRescanDisabled && (
          <Tooltip.Content side='top'>
            <Tooltip.Text>
              This functionality is currently not available for EVA.
            </Tooltip.Text>
          </Tooltip.Content>
        )}
      </Tooltip.Root>
    </>
  );
};

export default RescanControl;
